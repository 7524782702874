import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";
import HeroCareer from "./HeroCareer/HeroCareer";

export default function Career() {
  return (
    <div>
      <NavBar />
      <HeroCareer />
      <Footer />
    </div>
  );
}
