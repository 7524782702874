import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";
import "./HeroAbout.css";

export class HeroAbout extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div>
        <div className="heroabout">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="about-header-text wow fadeInDown">
                    About Us
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
        <div className="container who">
          <p className="text-center whobg wow fadeInUp">Who we are</p>
          <div className="container aboutheadercontainer wow fadeInUp">
            We are a Talent-as-a-service company that provides highly
            sought-after I.T Training, Staffing and Consulting (full-time,
            part-time, short-term) for individuals and Businesses
          </div>
        </div>
      </div>
    );
  }
}

export default HeroAbout;
