import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";
import HeroAbout from "./HeroAbout/HeroAbout";
import Team from "./Team/Team";

export default function About() {
  return (
    <div>
      <NavBar />
      <HeroAbout />
      <Team />
      <Footer />
      {/* <div>Team</div> */}
    </div>
  );
}
