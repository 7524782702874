import React from 'react';
import './Footer.css';
import logo1 from '../Assets/Images/logo.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Footer() {
  return (
    <div className="footer">
      <div className="container words text-center">
        <Row>
          <Col sm>
            <img src={logo1} alt="Nescotouch Technologies footer logo" />
          </Col>
          <Col sm>
            <div className="foot">
              <h3></h3>
              <p>©{new Date().getFullYear()} Nescotouch Technologies.</p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:contact@nescotouch.com"
                >
                  contact@nescotouch.com
                </a>
              </p>
              <p>{/* <b>CAC: 1989899</b> */}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
