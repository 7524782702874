import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import { useLocation, Link } from 'react-router-dom';
import './NavBar.css';

import begoLogo from '../../components/Assets/Images/logo.png';

const NavBar = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  //bg=dark
  return (
    <div>
      <Navbar expand="lg" fixed="top" className="navbar-header">
        <Navbar.Brand as={Link} to="/" className="brand">
          <img
            src={begoLogo}
            // width="250"
            height="50"
            className="d-inline-block align-top"
            alt="Nescotouch Technologies"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" id="nav-text">
              HOME
            </Nav.Link>
            {/* <NavDropdown
              id="nav-text"
              title="CERTIFICATIONS"
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
            >
              <NavDropdown.Item as={Link} to="/certification">
                Aws Solutions Architect
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link as={Link} to="/service" id="nav-text">
              SERVICES
            </Nav.Link>
            <Nav.Link as={Link} to="/career" id="nav-text">
              CAREERS
            </Nav.Link>
            <Nav.Link as={Link} to="/contactus" id="nav-text">
              CONTACT US
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/training" id="nav-text">
              Training
            </Nav.Link> */}

            <Nav.Link as={Link} to="/about" id="nav-text">
              ABOUT
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {/* <hr /> */}
    </div>
  );
};

export default NavBar;
