import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WOW from 'wowjs';
import './HeroCareer.css';

import betterTogetherImg from '../../components/Assets/Images/bettertogether.png';

export class HeroCareer extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render() {
    return (
      <div>
        <div className="herocareer">
          <div className="container">
            <Row>
              <Col>
                <div className="container">
                  <div className="career-header-text wow fadeInDown">
                    CAREERS
                  </div>
                </div>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
        <div className="career">
          <div className="container">
            <Row>
              <Col sm>
                <div className="better">
                  <h3>We're better together.</h3>
                  <p>
                    At Nescotouch Technologies, we’re dedicated to building a
                    workforce that reflects the diverse communities we serve and
                    where everyone feels empowered to bring their full,
                    authentic selves to work. Our values aren’t just words on a
                    page — we learn to live them every day, measure our success,
                    and continuously evolve. Together, we’re on a mission to
                    improve the state of the world.
                  </p>
                </div>
              </Col>
              <Col sm>
                <img src={betterTogetherImg} alt="better together" />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default HeroCareer;
